import React, { useEffect, useState } from 'react';
import fallbackLogoUrl from '../../../assets/images/osg-logo.png';
import CoreBootstrap from '@components/core_bootstrap';
import FeedbackForm from '@components/core_feedback';
import NotificationImgToIcon from '@components/common/NotificationImgToIcon';
import { VinBtn, VinDropdown } from '@components/vin';
import { fromNow } from '../core_utils';

// TODO:
// 1. Use permissions to determine which menu items to show
// 2. Use currentLogin to determine which menu items to show

const MenuItem = ({ divider, children, href, ...props }) => {
  if (divider) {
    return <li className="vin-menu__divider" />;
  }

  if (href && href.length > 0) {
    return (
      <li>
        <a href={href} {...props}>
          {children}
        </a>
      </li>
    );
  }

  return <li {...props}>{children}</li>;
};

const Header = params => {
  const {
    currentLoginHasActiveMembership,
    currentSubscription,
    permissions,
    navigationAccess,
    i18n,
    currentLogin,
    unreadMessages,
    rootUrl,
    logoUrl,
  } = params;

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [messages, setMessages] = useState(unreadMessages);

  // console.log('Header.jsx Params', params);

  // The locale wasn't changing with the rest of the site.
  I18n.locale = i18n.locale;

  // Current, easiest way to get count. Re-check every second
  const getCartCount = () =>
    JSON.parse(localStorage.getItem('state') ?? '{"checkout":{"lineItems":[]}}').checkout.lineItems.length;

  const [cartItemCount, setCartItemCount] = useState(getCartCount());

  useEffect(() => {
    let intervalRef = setInterval(() => {
      const itemCount = getCartCount();

      if (cartItemCount !== itemCount) {
        setCartItemCount(itemCount);
      }
    }, 1000);

    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  const hasMembership = currentSubscription && currentSubscription.id && currentSubscription.state == 'active';

  const navItems = {
    // url, exact_match, show menu
    home: ['/admin', true, navigationAccess.indexOf('admin') >= 0],
    training: ['/training', true, navigationAccess.indexOf('trainee') >= 0],
    my_profile: [
      '/training/profile',
      true,
      navigationAccess.indexOf('trainee') >= 0 && navigationAccess.indexOf('admin') < 0,
    ],

    forms: [
      '/training/forms',
      false,
      (navigationAccess.indexOf('admin') >= 0 && hasMembership) ||
        (currentLoginHasActiveMembership && navigationAccess.indexOf('trainee') >= 0),
    ],
    snippets: [
      ['/training/folders', '/training/snippets_share'],
      false,
      (navigationAccess.indexOf('admin') >= 0 && hasMembership) ||
        (currentLoginHasActiveMembership && navigationAccess.indexOf('trainee') >= 0),
    ],
    courses: ['/admin/course_library', false, navigationAccess.indexOf('admin') >= 0 && permissions.canManageUsers],
    users: [
      ['/admin/users', '/admin/trainee_import_reports'],
      false,
      navigationAccess.indexOf('admin') >= 0 && permissions.canManageUsers,
    ],

    builder: [
      '/admin/builder/courses',
      false,
      hasMembership && navigationAccess.indexOf('admin') >= 0 && permissions.canBuildCourses,
    ],

    reports: ['/admin/reports', false, navigationAccess.indexOf('admin') >= 0 && permissions.canViewAccountReports],
    branches: [
      '/admin/branches',
      false,
      navigationAccess.indexOf('admin') >= 0 && permissions.canManageAccountBranches,
    ],
    billing: [
      ['/admin/billing', '/admin/invoices'],
      false,
      navigationAccess.indexOf('admin') >= 0 && permissions.canManageAccountBranches,
    ], // Not sure why ruby was using this perm

    store: ['/store', false, !hasMembership && navigationAccess.indexOf('store') >= 0],
    subscription: ['/subscription', false, !hasMembership && navigationAccess.indexOf('store') >= 0],
    settings: [
      ['/admin/preferences', '/admin/rules', '/admin/user_fields', '/admin/user_restorations'],
      false,
      navigationAccess.indexOf('admin') >= 0,
    ],
  };

  const navMenuButtons = [];

  let selectedNavItem = null;
  // Find out which menu item to show as selected
  const urlPath = location.pathname.toLowerCase();

  // only do exact match this loop
  Object.keys(navItems).forEach(navItemName => {
    if (selectedNavItem || !navItems[navItemName][2]) {
      return;
    }

    const paths = typeof navItems[navItemName][0] === 'string' ? [navItems[navItemName][0]] : navItems[navItemName][0];
    for (let path of paths) {
      if (navItems[navItemName][1] && path == urlPath) {
        selectedNavItem = navItemName;
      }
    }
  });

  // only do non-exact match this loop
  Object.keys(navItems).forEach(navItemName => {
    if (selectedNavItem || !navItems[navItemName][2]) {
      return;
    }

    const paths = typeof navItems[navItemName][0] === 'string' ? [navItems[navItemName][0]] : navItems[navItemName][0];
    for (let path of paths) {
      if (!navItems[navItemName][1] && urlPath.startsWith(path)) {
        selectedNavItem = navItemName;
      }
    }
  });

  // console.log('selectedNavItem', selectedNavItem);
  // Check if admin page else Default to home if no matching paths
  if (urlPath.startsWith('/sales')) {
    selectedNavItem = 'distributor';
  } else if (!selectedNavItem) {
    selectedNavItem = 'home';
  }

  // Render menu items to the navMenuButtons array
  Object.keys(navItems).forEach(navItemName => {
    const navItem = navItems[navItemName];
    const itemSelected = navItemName === selectedNavItem;

    if (!navItem[2]) {
      return;
    }

    navMenuButtons.push(
      <MenuItem
        key={navItemName}
        href={typeof navItem[0] === 'string' ? navItem[0] : navItem[0][0]}
        className={itemSelected ? 'menu-item menu-item--current' : 'menu-item'}
      >
        {I18n.t('navigation.' + navItemName)}
      </MenuItem>
    );
  });

  useEffect(() => {
    const handleResize = () => {
      const navMenu = document.querySelector('.navigation__inner__menu');
      const moreDropdownMenu = document.querySelector('#more-dropdown-menu');
      const moreDropdown = document.querySelector('#more-dropdown');

      // Reset the moreDropdownMenu
      while (moreDropdownMenu.firstChild) {
        navMenu.insertBefore(moreDropdownMenu.firstChild, moreDropdown);
      }

      const navMenuItems = Array.from(navMenu.children).filter(item => item !== moreDropdown);
      const moreDropdownWidth = moreDropdown.offsetWidth;
      let availableWidth = navMenu.offsetWidth - moreDropdownWidth - 50;
      let requiredWidth = 0;

      navMenuItems.forEach(item => {
        requiredWidth += item.offsetWidth;
        if (requiredWidth > availableWidth) {
          moreDropdownMenu.appendChild(item);
        }
      });

      if (moreDropdownMenu.children.length === 0) {
        moreDropdown.style.display = 'none';
      } else {
        moreDropdown.style.display = 'block';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    window.addEventListener('load', handleResize); // Wait until page is finished loading

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <CoreBootstrap>
        <FeedbackForm closeModal={() => setFeedbackModalOpen(false)} isOpen={feedbackModalOpen} />
      </CoreBootstrap>
      <div className="top-nav">
        <div className="navigation__inner">
          <div className="navigation__inner__logo">
            {/* This needs to look for main_app.root_url from the environment */}
            <a href={rootUrl}>
              <img
                alt={I18n.t('navigation.home')}
                src={logoUrl}
                onError={e => {
                  e.target.onError = null;
                  e.target.src = fallbackLogoUrl;
                }}
              />
            </a>
          </div>
          <ul className="navigation__inner__right">
            {navigationAccess.indexOf('trainee') >= 0 && (
              <li>
                <VinDropdown
                  id="help-dropdown"
                  buttonClasses="vin-btn-link vin-btn--default"
                  label={
                    <React.Fragment>
                      <i className="fa fa-fw fa-question-circle" aria-hidden="true" />
                      <span>{I18n.t('navigation.help')}</span>
                    </React.Fragment>
                  }
                >
                  <ul className="vin-menu">
                    <MenuItem href="/help?category=help">{I18n.t('navigation.request_support')}</MenuItem>
                    <MenuItem href="/help?category=bug">{I18n.t('navigation.report_bug')}</MenuItem>
                    <MenuItem
                      href="#"
                      onClick={() => {
                        setFeedbackModalOpen(true);
                      }}
                    >
                      {I18n.t('navigation.provide_feedback')}
                    </MenuItem>
                  </ul>
                </VinDropdown>
              </li>
            )}

            {!hasMembership && navigationAccess.indexOf('store') >= 0 && cartItemCount > 0 && (
              <li>
                <VinBtn kind="link" variant="default" href="/store/checkout" icon count={cartItemCount}>
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                  <span className="sr-only">{I18n.t('navigation.my_cart')}</span>
                </VinBtn>
              </li>
            )}

            {currentLogin && (
              <li>
                <VinDropdown
                  id="notifications_popover"
                  buttonClasses="vin-btn-link vin-btn--icon vin-btn--default"
                  data-remote="false"
                  label={
                    <React.Fragment>
                      {messages.length > 0 && <div className="vin-btn__count">{messages.length}</div>}
                      <i className="fa fa-bell" aria-hidden="true" />
                      <span className="sr-only">{I18n.t('navigation.notifications')}</span>
                    </React.Fragment>
                  }
                >
                  <div className="notifications">
                    <div className="notifications__title">{I18n.t('navigation.notifications')}</div>
                    {messages.length > 0 && (
                      <div className="notifications__mark">
                        <form
                          method="POST"
                          action="/training/notifications/mark_as_read"
                          data-remote="true"
                          onSubmit={() => setMessages([])}
                        >
                          <VinBtn kind="link" type="submit">
                            {I18n.t('navigation.mark_all_as_read')}
                          </VinBtn>
                        </form>
                      </div>
                    )}

                    <ul className="notifications__list">
                      {messages.length > 0 ? (
                        messages.slice(0, 5).map(message => (
                          <li key={message.id}>
                            {message.link ? (
                              <a className="notification-item-link" href={message.link}>
                                <div className="notification-item notification-item--unread">
                                  <div className="notification-item__icon">
                                    <NotificationImgToIcon img={message.thumb} />
                                  </div>
                                  <div className="notification-item__content">
                                    <div className="notification-item__title">{message.title}</div>
                                    <p
                                      className="notification-item__description"
                                      dangerouslySetInnerHTML={{ __html: message.text }}
                                    />
                                    <div className="notification-item__time">
                                      {fromNow(new Date(message.created_at))}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ) : (
                              <div className="notification-item notification-item--unread">
                                <div className="notification-item__icon">
                                  <NotificationImgToIcon img={message.thumb} />
                                </div>
                                <div className="notification-item__content">
                                  <div className="notification-item__title">{message.title}</div>
                                  <p
                                    className="notification-item__description"
                                    dangerouslySetInnerHTML={{ __html: message.text }}
                                  />
                                  <div className="notification-item__time">{fromNow(new Date(message.created_at))}</div>
                                </div>
                              </div>
                            )}
                          </li>
                        ))
                      ) : (
                        <li className="notification-item notification-item--none">{I18n.t('navigation.no_unread')}</li>
                      )}
                    </ul>
                    <div className="notifications__all">
                      {messages.length > 0 ? (
                        <VinBtn variant="primary" href="/training/notifications/unread">
                          {I18n.t('navigation.see_unread')}
                        </VinBtn>
                      ) : (
                        <VinBtn variant="primary" href="/training/notifications">
                          {I18n.t('navigation.see_notifications')}
                        </VinBtn>
                      )}
                    </div>
                  </div>
                </VinDropdown>
              </li>
            )}

            {currentLogin ? (
              <li>
                <VinDropdown
                  buttonClasses="vin-btn-link vin-btn--default"
                  id="user"
                  label={
                    <React.Fragment>
                      <span className="full_name">{currentLogin?.full_name}</span>
                      <i className="fa fa-chevron-down" aria-hidden="true" />
                    </React.Fragment>
                  }
                >
                  <ul className="vin-menu">
                    <MenuItem className="vin-menu__userinfo">
                      <div>{I18n.t('navigation.signed_in_as')}</div>
                      <strong>{currentLogin?.username}</strong>
                    </MenuItem>
                    <MenuItem href="/training/profile">{I18n.t('navigation.my_profile')}</MenuItem>
                    {navigationAccess.indexOf('admin') >= 0 && (
                      <MenuItem href="/admin/preferences">{I18n.t('navigation.settings')}</MenuItem>
                    )}
                    {I18n.locale == 'en' ? (
                      <MenuItem href="/language?locale=fr">Français</MenuItem>
                    ) : (
                      <MenuItem href="/language?locale=en">English</MenuItem>
                    )}
                    <MenuItem divider />
                    <MenuItem className="vin-menu__logout" href="/logins/sign_out">
                      {I18n.t('navigation.log_out')}
                    </MenuItem>
                  </ul>
                </VinDropdown>
              </li>
            ) : (
              <li>
                <a href="/logins/sign_in">{I18n.t('navigation.sign_in')}</a>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="second-nav">
        <div className="navigation__inner" id="nav-menu">
          <ul className="navigation__inner__menu">
            {navMenuButtons}
            <li id="more-dropdown" style={{ display: 'none' }}>
              <VinDropdown
                buttonClasses="vin-btn  vin-btn--default vin-btn--sm"
                label={
                  <React.Fragment>
                    <span>{I18n.t('navigation.more')}</span>
                    <i className="fa fa-chevron-down" />
                  </React.Fragment>
                }
              >
                <ul id="more-dropdown-menu" className="vin-menu"></ul>
              </VinDropdown>
            </li>
          </ul>
          <ul className="navigation__inner__right">
            <li>
              {navigationAccess.indexOf('sales') >= 0 && (
                <VinDropdown
                  id="sales-dropdown"
                  buttonClasses={
                    'vin-btn vin-btn--sm vin-btn--pop' + (selectedNavItem == 'distributor' ? ' vin-btn--primary' : '')
                  }
                  label={
                    <React.Fragment>
                      <span>{I18n.t('navigation.distributor')}</span>
                      <i className="fa fa-chevron-down" />
                    </React.Fragment>
                  }
                >
                  <ul className="vin-menu">
                    <MenuItem href="/sales/reports">{I18n.t('navigation.sales_reports')}</MenuItem>
                    <MenuItem href="/sales/invoices">{I18n.t('navigation.invoices')}</MenuItem>
                    <MenuItem href="/sales/accounts">{I18n.t('navigation.clients')}</MenuItem>

                    {navigationAccess.indexOf('sales') >= 0 && (
                      <MenuItem href="/sales/query_reports">{I18n.t('navigation.queries')}</MenuItem>
                    )}
                    <MenuItem href="/store/products">{I18n.t('navigation.products')}</MenuItem>
                  </ul>
                </VinDropdown>
              )}
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
